export default [
    {
        id: "pal-1",
class: "pal",
        question:'What beliefs do you have about yourself?',
    title: "words", 
},
    {
        id: "pal-2",
class: "pal",
        question:'When did you stop listening?',
    title: "words", 
},
    {
        id: "pal-3",
class: "pal",
        question:'What would you like to say no to in this situation?',
    title: "words", 
},
    {
        id: "pal-4",
class: "pal",
        question:'How many times did you say yes when you wanted to say no in this situation/subject? What can you do now?',
    title: "words", 
},
    {
        id: "pal-5",
class: "pal",
        question:'What story are you telling yourself about this situation/subject and which would you like to tell yourself instead?',
    title: "words", 
},
    {
        id: "pal-6",
class: "pal",
        question:'Define this situation with five words',
    title: "words", 
},
    {
        id: "pal-7",
class: "pal",
        question:'Define yourself in this situation with five words',
    title: "words", 
},
    {
        id: "pal-8",
class: "pal",
        question:'What is the word that most empowers you right now?',
    title: "words", 
},
    {
        id: "pal-9",
class: "pal",
        question:'Name al least 10 rules that define the boundaries of what’s possible for you in this situation/subject',
    title: "words", 
},
    {
        id: "pal-10",
class: "pal",
        question: "Who do you need to express gratitude to? Have you done it?",
    title: "words", 
},
    {
        id: "pal-11",
class: "pal",
        question:'If the situation/subject could speak, what would it say?',
    title: "words", 
},
    {
        id: "pal-12",
class: "pal",
        question:'How do your expectations relate to what is really going on?',
    title: "words", 
},
    {
        id: "pal-13",
class: "pal",
        question:'Who do you need to have a conversation with and you haven’t?',
    title: "words", 
},
    {
        id: "pal-14",
class: "pal",
        question:'On a scale of 1 to 10, how would you rate the level of conversation that you are having for this situation/subject?',
    title: "words", 
},
    {
        id: "pal-15",
class: "pal",
        question:'What are you asking yourself in this situation/subject? What are you not asking yourself that could make a difference?',
    title: "words", 
},
    {
        id: "pal-16",
class: "pal",
        question:'If this situation was a game, which one would it be? Why?',
    title: "words", 
},
    {
        id: "pal-17",
class: "pal",
        question:'What does this situation/subject say about you?',
    title: "words", 
},
    {
        id: "pal-18",
class: "pal",
        question:'Mention at list three hidden benefits you get from staying where you are.',
    title: "words", 
},
    {
        id: "pal-19",
class: "pal",
        question:'Which are the values involved in this situation/subject? Are you being coherent with them?',
    title: "words", 
},
    {
        id: "pal-20",
class: "pal",
        question:'What do you need to tell yourself about this situation/subject?',
    title: "words", 
},
]