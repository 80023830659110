import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import {ContainerHome, BannerButton} from '../../src/components/ui/Home'
//import board from '../../src/assets/img/banner-ppal.jpg'
import { GiClick } from "react-icons/gi";

const Home = () => {

    const { t, i18n } = useTranslation();

    // const styleBoard = {
    //     backgroundImage: "url("+board+")"
    // }

    useEffect(() => {
        
        document.querySelector('.navbar').classList.add('active')
        
        document.querySelector('.LanguageSelector').classList.add('active')
        
        
        
    },[])


    return (
        <ContainerHome lang={i18n.language} >
            
            <div className="content" >
                {/* <img src={board} className="bg" alt="five powers" /> */}
                
                <h1> 
                    {t('banner_title')} 
                </h1>
            </div>
            <BannerButton>
                    <div className="gradient-text">
                    <a href="https://yourfivepowers.com/el-libro/" target="_blank" >
                        {t('download_book_here')} <span><GiClick /></span>
                    </a>
                    </div>
            </BannerButton>
        </ContainerHome>
    )
}

export default withRouter(Home)
