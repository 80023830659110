export default [
    {
        id: "emo-1",
class: "emo",
        question:'What emotion does this situation/subject produce in you? Can you name it?',
    title: "emotions and feelings", 
},
    {
        id: "emo-2",
class: "emo",
        question:'What are you reacting to?',
    title: "emotions and feelings", 
},
    {
        id: "emo-3",
class: "emo",
        question:'What makes you feel uncomfortable?',
    title: "emotions and feelings", 
},
    {
        id: "emo-4",
class: "emo",
        question:'Are you afraid? What are you afraid of?',
    title: "emotions and feelings", 
},
    {
        id: "emo-5",
class: "emo",
        question:'Which is the strongest feeling you have for this situation/subject?',
    title: "emotions and feelings", 
},
    {
        id: "emo-6",
class: "emo",
        question:'What reactions does this situation/subject produce in you?',
    title: "emotions and feelings", 
},
    {
        id: "emo-7",
class: "emo",
        question:'How can you show that you care for this situation/subject?',
    title: "emotions and feelings", 
},
    {
        id: "emo-8",
class: "emo",
        question:'How do you feel about yourself in relation to this situation/subject?',
    title: "emotions and feelings", 
},
    {
        id: "emo-9",
class: "emo",
        question:'What are you willing to forgive?',
    title: "emotions and feelings", 
},
    {
        id: "emo-10",
class: "emo",
        question:'What are you not willing to forgive?',
    title: "emotions and feelings", 
},
    {
        id: "emo-11",
class: "emo",
        question:'Which emotions do you need to invite to face this situation/subject?',
    title: "emotions and feelings", 
},
    {
        id: "emo-12",
class: "emo",
        question:'What do you need to let go?',
    title: "emotions and feelings", 
},
    {
        id: "emo-13",
class: "emo",
        question:'How do you feel acting like you do? How could you act differently??',
    title: "emotions and feelings", 
},
    {
        id: "emo-14",
class: "emo",
        question:'What are you resisting in this situation/subject?',
    title: "emotions and feelings", 
},
    {
        id: "emo-15",
class: "emo",
        question:'What emotional cost are you paying right now?',
    title: "emotions and feelings", 
},
    {
        id: "emo-16",
class: "emo",
        question:'Who is paying emotional costs for this situation/subject?',
    title: "emotions and feelings", 
},
    {
        id: "emo-17",
class: "emo",
        question:'What are your secret expectations? Did you express them? ',
    title: "emotions and feelings", 
},
    {
        id: "emo-18",
class: "emo",
        question:'What is your secret hope?',
    title: "emotions and feelings", 
},
    {
        id: "emo-19",
class: "emo",
        question:'Have you ever felt like this in the past? What similarities can you find?',
    title: "emotions and feelings", 
},
    {
        id: "emo-20",
class: "emo",
        question:'If this situation/subject was to be seen from FUN, how would it be?',
    title: "emotions and feelings", 
},
]