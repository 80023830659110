export default [
    {
        id: "emo-1",
class: "emo",
        question:'¿Qué emoción te produce este tema? ¿Puedes nombrarla?',
    title: "emociones y sentimientos", 
},
    {
        id: "emo-2",
class: "emo",
        question:'¿A qué estás reaccionando?',
    title: "emociones y sentimientos", 
},
    {
        id: "emo-3",
class: "emo",
        question:'¿Qué te hace sentir incómodo/a?',
    title: "emociones y sentimientos", 
},
    {
        id: "emo-4",
class: "emo",
        question:'¿Sientes miedo? ¿A qué?',
    title: "emociones y sentimientos", 
},
    {
        id: "emo-5",
class: "emo",
        question:'¿Cuál es el sentimiento más fuerte que tienes frente a esta situación/tema?',
    title: "emociones y sentimientos", 
},
    {
        id: "emo-6",
class: "emo",
        question:'¿Qué reacciones te produce esta situación/tema?',
    title: "emociones y sentimientos", 
},
    {
        id: "emo-7",
class: "emo",
        question:'¿Qué gatilla tu reacción frente a este tema?',
    title: "emociones y sentimientos", 
},
    {
        id: "emo-8",
class: "emo",
        question:'¿Cómo te sientes acerca de ti mismo en relación con este tema?',
    title: "emociones y sentimientos", 
},
    {
        id: "emo-9",
class: "emo",
        question:'¿Qué estás dispuesto a perdonar?',
    title: "emociones y sentimientos", 
},
    {
        id: "emo-10",
class: "emo",
        question:'¿Qué no estás dispuesto a perdonar?',
    title: "emociones y sentimientos", 
},
    {
        id: "emo-11",
class: "emo",
        question:'¿A qué emociones necesitas invitar para este tema/situación?',
    title: "emociones y sentimientos", 
},
    {
        id: "emo-12",
class: "emo",
        question:'¿Qué necesitas soltar?',
    title: "emociones y sentimientos", 
},
    {
        id: "emo-13",
class: "emo",
        question:'¿Cómo te sientes actuando como actúas? ¿Te gustaría actuar de otra manera, cómo?',
    title: "emociones y sentimientos", 
},
    {
        id: "emo-14",
class: "emo",
        question:'¿Qué estás resistiendo en este tema/situación?',
    title: "emociones y sentimientos", 
},
    {
        id: "emo-15",
class: "emo",
        question:'¿Qué costo emocional estás pagando ahora?',
    title: "emociones y sentimientos", 
},
    {
        id: "emo-16",
class: "emo",
        question:'¿Quiénes están pagando costos emocionales por este tema/situación?',
    title: "emociones y sentimientos", 
},
    {
        id: "emo-17",
class: "emo",
        question:'¿Qué deseas que pase y no estás expresando? ¿Cuál es tu deseo secreto?',
    title: "emociones y sentimientos", 
},
    {
        id: "emo-18",
class: "emo",
        question:'¿Cuál es tu esperanza oculta?',
    title: "emociones y sentimientos", 
},
    {
        id: "emo-19",
class: "emo",
        question:'¿Hubo otra situación en la que te hayas sentido así? ¿Cuál? ¿En qué se parece a esta?',
    title: "emociones y sentimientos", 
},
    {
        id: "emo-20",
class: "emo",
        question:'¿Si este tema/situación fuera visto desde la diversión, cómo sería?',
    title: "emociones y sentimientos", 
},
]