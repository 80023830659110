import styled from '@emotion/styled';

import banner from '../../assets/img/banner.jpg'
import background from '../../assets/img/banner-ppal.jpg'

export const ContainerHome = styled.div`
    display: flex;
    width: 100%;
    min-height: 100vh;
    padding: 0;
    margin-top: -93px;
    background-image: url(${background});
    background-position: bottom;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;

    .content{
        width: 100%;
        
        img.bg{
            position: absolute;
            bottom: 0;
            z-index: -1;
            width: 100%;
        }
        h1{
            font-size: 60px;
            text-align: center;
            font-weight: bold;
            color: #fff;
            text-transform: uppercase;
            width: 90%;
            margin: auto;
            @media screen and (max-width: 600px) {
                font-size: 50px;
                width: 100px;
            }
        }

        a{
            text-decoration: none;
            text-align: center;
        }
        
        .button{
            background-color: #131313;
            color: #fff;
            text-transform: uppercase;
            font-size: 40px;
            font-weight: 500;
            width: ${props => props.lang === "en" ? '230px' : '280px'};
            padding: 20px 30px;
            border-radius: 30px;
            margin: auto;
            text-align: center;
        }

        .icon{
            left: 0;
            right: 0;
            margin-left: auto;
            margin-right: auto;
            width: 60px;
            position: absolute;
            bottom: 30px;
        }
        .home-button{
                padding: 20px 30px;
                border-radius: 50px;
                background: #9E337B;
                color: white;
                font-size: 30px;
                font-weight: 500;
                width: 350px;
                margin: auto;
                border: none;
                text-transform: uppercase;
        }
    }

    @media screen and (max-width: 600px){
        margin-top: -135px;
        .content{
            h1{
                font-size: 30px;
                width: 90%;
            }
        }
    }
    
`

export const BannerButton = styled.div`
        position: absolute;
        bottom: 0;
        height: 70px;
        width: 100%;
        overflow: hidden;
        background: url(${banner});
        background-position: bottom;
        background-size: cover;
        background-repeat: no-repeat;

        .gradient-text{
            padding: 0 80px;
            display: flex;
            position: relative;
            z-index: 10;
            color: white; 
            font-size: 22px;
            align-items: center;
            justify-content: center;
            font-weight: 600;
            width: 100%;
            height: 100%;
            text-transform: uppercase;
            letter-spacing: 1.5px;

            a{
                    text-decoration: none;
                    color: #ffffff;
                    position: relative;
                    font-weight: 500;
                    text-transform: capitalize;
                    
                    &::after{
                        width: calc(100% - 40px);
                        position: absolute;
                        content: '';
                        height: 1.5px;
                        background: #ffffff;
                        bottom: 0;
                        left: 0;
                    }
                    span{
                        
                        svg{
                            font-size: 30px;
                        }
                    }

                    &:hover{
                        transform: scale(1.1);
                    }
            }
        }

        @media screen and (max-width: 600px){
            height: 170px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            background-position: center;

            .gradient-text{
                display: flex;
                height: 170px;
                text-align: center;
                width: 100%;
                font-size: 20px;
                justify-content: center;
                align-items: center;
                padding: 10px;

                a::after{
                    display: none;
                }
                
            }
        }

`