export default [
    {
        id: "int-1",
class: "intu",
        question:'¿Qué te dice la intuición en este momento acerca de este tema/situación?',
    title: "intuicion", 
},
    {
        id: "int-2",
class: "intu",
        question:'¿Qué haría el amor en esta situación?',
    title: "intuicion", 
},
    {
        id: "int-3",
class: "intu",
        question:'¿Qué es lo que no estás queriendo escuchar?',
    title: "intuicion", 
},
    {
        id: "int-4",
class: "intu",
        question:'¿Qué es lo que no sabes que sabes?',
    title: "intuicion", 
},
    {
        id: "int-5",
class: "intu",
        question:'¿Qué pretendes no saber?',
    title: "intuicion", 
},
    {
        id: "int-6",
class: "intu",
        question:'¿Qué tiene más peso para ti en esta situación: tu intuición o tu lógica?',
    title: "intuicion", 
},
    {
        id: "int-7",
class: "intu",
        question:'¿Qué te dice tu cuerpo? Encuentra ahora tres registros corporales frente a este tema/situación.',
    title: "intuicion", 
},
    {
        id: "int-8",
class: "intu",
        question:'¿Quién eres? ¿Quién eres realmente?',
    title: "intuicion", 
},
    {
        id: "int-9",
class: "intu",
        question:'¿Qué decisión estás aplazando?',
    title: "intuicion", 
},
    {
        id: "int-10",
class: "intu",
        question:'¿Si Dios, o la energía superior en la que crees te hablara, qué te diría ahora?',
    title: "intuicion", 
},
    {
        id: "int-11",
class: "intu",
        question:'¿Qué parte de ti deberías invitar a tomar decisiones en este tema/situación?',
    title: "intuicion", 
},
    {
        id: "int-12",
class: "intu",
        question:'¿Qué está esperando manifestarse en este momento?',
    title: "intuicion", 
},
    {
        id: "int-13",
class: "intu",
        question:'¿Qué aprendizaje trae para ti esta situación?',
    title: "intuicion", 
},
    {
        id: "int-14",
class: "intu",
        question:'¿Qué está queriendo salir a la luz en esta situación/tema?',
    title: "intuicion", 
},
    {
        id: "int-15",
class: "intu",
        question:'¿Qué te inspira?',
    title: "intuicion", 
},
    {
        id: "int-16",
class: "intu",
        question:'¿Qué quieres controlar?',
    title: "intuicion", 
},
    {
        id: "int-17",
class: "intu",
        question:'¿Si no hubiera riesgos, qué harías?',
    title: "intuicion", 
},
    {
        id: "int-18",
class: "intu",
        question:'¿Si pudieras pedir tres deseos cuáles serían?',
    title: "intuicion", 
},
    {
        id: "int-19",
class: "intu",
        question:'¿Qué estás buscando en este camino, la verdad o la felicidad?',
    title: "intuicion", 
},
    {
        id: "int-20",
class: "intu",
        question:'¿Qué es posible para ti?',
    title: "intuicion", 
},
    {
        id: "int-21",
class: "intu",
        question:'¿Estoy dispuesto a asumir el riesgo de seguir mi intuición?',
    title: "intuicion", 
},
]