export default [
    {   
        id : "acc-1",
class: "acc",
        question:'What prevents you from moving forward?',
        title: "action", 
},
    {
        id: "acc-2",
class: "acc",
        question:'What thrives your action in this situation/subject?',
        title: "action", 
},
    {
        id: "acc-3",
class: "acc",
        question:'Have you asked for help? To whom?',
    title: "action", 
},
    {
        id: "acc-4",
class: "acc",
        question:'What do you need to do in order to succeed?',
    title: "action", 
},
    {
        id: "acc-5",
class: "acc",
        question:'What paralyzes you?',
    title: "action", 
},
    {
        id: "acc-6",
class: "acc",
        question:'If commitment is action, what is your commitment?',
    title: "action", 
},
    {
        id: "acc-7",
class: "acc",
        question:'In which mood do you need to be to put yourself in action?',
    title: "action", 
},
    {
        id: "acc-8",
class: "acc",
        question:'List three excuses why you are not making the decisions you need to take action right now',
    title: "action", 
},
    {
        id: "acc-9",
class: "acc",
        question:'List three reasons why you need to take action in this situation/subject',
    title: "action", 
},
    {
        id: "acc-10",
class: "acc",
        question:'Which would be a visible outcome for you and others related to the actions you take for this situation?',
    title: "action", 
},
    {
        id: "acc-11",
class: "acc",
        question:'What resources do you need to take action? Have you done something about it?',
    title: "action", 
},
    {
        id: "acc-12",
class: "acc",
        question:'Which is the minimum, acceptable, non-negotiable result you start from?',
    title: "action", 
},
    {
        id: "acc-13",
class: "acc",
        question:'What body do you need to face this situation/subject?',
    title: "action", 
},
    {
        id: "acc-14",
class: "acc",
        question:'What is your purpose?',
    title: "action", 
},
    {
        id: "acc-15",
class: "acc",
        question:'If you could give something in advance, what would it be?',
    title: "action", 
},
    {
        id: "acc-16",
class: "acc",
        question:'If you could make an exchange, which would it be?',
    title: "action", 
},
    {
        id: "acc-17",
class: "acc",
        question:'What have you done for things to turn out the way they did?',
    title: "action", 
},
    {
        id: "acc-18",
class: "acc",
        question:'What didn’t you do that put you where you are now?',
    title: "action", 
},
    {
        id: "acc-19",
class: "acc",
        question:'On a scale from 1 to 10 how important is it to take action in this situation/subject? What are you waiting for?',
    title: "action", 
},
    {
        id: "acc-20",
class: "acc",
        question:"Do you have any action plan? Which is the first step?",
    title: "action", 
},
    
]