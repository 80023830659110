export default [
    {
        id: "sor-1",
class: "sorprise",
        question:'Escucha la visión de los otros. Pide su opinión',
    title: "sorpresa", 
},
    {
        id: "sor-2",
class: "sorprise",
        question:'Defiende el punto de vista opuesto',
    title: "sorpresa", 
},
    {
        id: "sor-3",
class: "sorprise",
        question:'Dobla la apuesta: ¿Qué propones?',
    title: "sorpresa", 
},
    {
        id: "sor-4",
class: "sorprise",
        question:'Si tu vida estuviera en juego: ¿Qué harías?',
    title: "sorpresa", 
},
    {
        id: "sor-5",
class: "sorprise",
        question:'Busca tres alternativas posibles y válidas para lograr lo que quieres, distintas a las que ya contemplaste',
    title: "sorpresa", 
},
    {
        id: "sor-6",
class: "sorprise",
        question:'Inviertre el escenario: ¿Si tu fueras el otro, como te verías?',
    title: "sorpresa", 
},
    {
        id: "sor-7",
class: "sorprise",
        question:'Peor escenario - Trágate un sapo: ¿Qué es lo peor que te puede pasar si todo sale mal?',
    title: "sorpresa", 
},
    {
        id: "sor-8",
class: "sorprise",
        question:'¿Si el destino te diera una sorpresa, cuál sería?',
    title: "sorpresa", 
},
    {
        id: "sor-9",
class: "sorprise",
        question:'¿Quieres lograrlo o quieres tener razón?',
    title: "sorpresa", 
},
    {
        id: "sor-10",
class: "sorprise",
        question:'Si jugaras Ganar-Ganar… Si jugaras para no perder… Si jugaras para perder ¿Cómo sería en cada caso? ',
    title: "sorpresa", 
},
    {
        id: "sor-11",
class: "sorprise",
        question:'¿Qué aprendizaje hay en esta situación?',
    title: "sorpresa", 
},
    {
        id: "sor-12",
class: "sorprise",
        question:'¿Identifica una persona que tenga valor en tu vida: cuál será el legado para el/ella una vez que lo logres?',
    title: "sorpresa", 
},
    {
        id: "sor-13",
class: "sorprise",
        question:'¿En qué no has pensado aún?',
    title: "sorpresa", 
},
    {
        id: "sor-14",
class: "sorprise",
        question:'¿Cuál sería un resultado inesperado?',
    title: "sorpresa", 
},
    {
        id: "sor-15",
class: "sorprise",
        question:'Dibuja el resultado',
    title: "sorpresa", 
},
    {
        id: "sor-16",
class: "sorprise",
        question:'Identifica dos bloqueos. Transfórmalos en oportunidad',
    title: "sorpresa", 
},
    {
        id: "sor-17",
class: "sorprise",
        question:'¿Si fuera una canción, cuál representa tu situación actual y cuál representa la deseada?',
    title: "sorpresa", 
},
    {
        id: "sor-18",
class: "sorprise",
        question:'¿Si fuera una película, cuál representa tu situación actual y cuál la deseada?',
    title: "sorpresa", 
},
    {
        id: "sor-19",
class: "sorprise",
        question:'¿Si pudieras pedir tres deseos, cuáles serían?',
    title: "sorpresa", 
},
    {
        id: "sor-20",
class: "sorprise",
        question:'Toma una carta de decisión y haz lo que te dice',
    title: "sorpresa", 
},
    {
        id: "sor-21",
class: "sorprise",
        question:'¿Pregúntale a un jugador, qué haría en esta situación?',
    title: "sorpresa", 
},
    {
        id: "sor-22",
class: "sorprise",
        question:'¿Si un niño tuviera la respuesta, qué te diría?',
    title: "sorpresa", 
},
    {
        id: "sor-23",
class: "sorprise",
        question:'Consigue la respuesta de un niño',
    title: "sorpresa", 
},
    {
        id: "sor-24",
class: "sorprise",
        question:'¿Qué pregunta no te has hecho todavía?',
    title: "sorpresa", 
},
]