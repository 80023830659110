import React from 'react'
import { useTranslation } from "react-i18next";
import { PrivacyContainer } from './ui/Privacy'

const Privacy = () => {

    const { t } = useTranslation();

    return (
        <PrivacyContainer >
            <div className="container">
                <h3>
                    {t('footer_privacy')}
                </h3>
                <div className="paragraph">
                    <p>
                    En Liderarte Performance & Coaching, sensibilizados con las necesidades de los usuarios de Internet y conscientes de la 
                    importancia de la rigurosa privacidad de la información personal que nos confían, incluimos esta declaración de privacidad 
                    con el objeto de que seas consciente de la política en el tratamiento de los datos personales que obtiene de sus visitantes 
                    y usuarios.
                    </p>
                    <p>
                    Como usuario, aceptas estas condiciones por el mero hecho de leer, visualizar o navegar en el sitio. De no aceptarlas, d
                    ebes abandonar el sitio, sin hacer uso alguno de él y su contenido, y sin acceder a las páginas enlazadas.
                    </p>
                </div>
                <div className="paragraph">
                    <div className="title">
                        RESPONSABLE DEL TRATAMIENTO DE DATOS
                    </div>
                    <p>
                        El titular del sitio y responsable del tratamiento de datos es Liderarte Performance & Coaching S.A. DE C.V, para 
                        contactar puedes utilizar la dirección de correo electrónico <a href="mailto:contacto@liderarte.com.mx">contacto@liderarte.com.mx</a> y/o <a href="mailto:hello@yourfivepowers.com">hello@yourfivepowers.com</a>.
                    </p>
                </div>
                <div className="paragraph">
                    <div className="title">
                        FINALIDAD DEL TRATAMIENTO DE DATOS
                    </div>
                    <p>
                        En nuestros sitios web, existen unos apartados específicos donde puedes anotar tus datos para recibir información sobre actualizaciones de nuestra web y de algunos los servicios que se distribuyen. Nosotros te aseguramos que la información que nos facilites será gestionada de forma totalmente confidencial.
                    </p>
                    <p>
                        Formulario de contacto: Mantener el contacto contigo a los fines de hacerte llegar promociones y/o información de nuestros servicios.
                    </p>
                    <p>
                        Formularios de Inscripción o Registración: inscribirte o regístrate en cualquiera de las actividades y/o servicios sean estos gratuitos o no y posteriormente mantener el contacto contigo a los fines de hacerte llegar promociones y/o información de nuestros servicios.
                    </p>
                    <p>
                        Los datos se almacenarán mientras exista previsión de su uso para el fin por el que fueron recabados.
                    </p>
                    <p>
                        No se realizan tomas de decisiones automatizadas con tus datos.
                    </p>
                </div>
                <div className="paragraph">
                    <div className="title">
                        LEGITIMACIÓN DEL USO DE IMAGEN
                    </div>
                    <p>
                        Liderarte Performance & Coaching SA de CV y/o a Tus Cinco Poderes podrá utilizar tu imagen personal, en forma fija o en movimiento, incluyendo tu voz o testimonio escrito, a fin de realizar un film publicitario, placas promocionales, y/o fotografías, las cuales Liderarte Performance y Coaching SA de CV y/o Tus Cinco Poderes podrá modificar o editar en la medida que ello sea técnica o estéticamente necesario a exclusivo criterio de Liderarte Performance y Coaching SA de CV y/o Tus Cinco Poderes, así como su voz.
                    </p>
                    <p>
                        En este sentido, Liderarte Performance & Coaching SA de CV podrá a su exclusivo criterio difundir, comunicar y/o publicar el film publicitario, placas promocionales, y/o fotografías y/o cualquier material promocional que incluya tu imagen en forma fija o en movimiento, incluyendo tu voz o testimonio escrito, como ser un webinar, workshop, taller y/o entrenamiento del que hubieras participado sea el mismo gratuito o no, tanto en redes sociales y/o plataformas de internet, incluyendo sin limitar a Instagram, Facebook, pagina web de Liderarte Performance y Coaching SA de CV y/o Tus Cinco Poderes.
                    </p>
                    <p>
                        Se aclara que el film publicitario, placas promocionales, y/o fotografías y/o cualquier material promocional que incluya tu imagen en forma fija o en movimiento, incluyendo tu voz o testimonio escrito, como ser un webinar, workshop, taller y/o entrenamiento del que hubieras participado sea el mismo gratuito o no, únicamente podrá ser utilizado por Liderarte Performance y Coaching SA de CV y/o Tus Cinco Poderes a los efectos de la producción, difusión, comunicación, promoción y/o publicación de sus servicios o actividades, sin que Liderarte Performance y Coaching SA de CV y/o Tus Cinco Poderes y/o cualquier tercero pueda utilizar tu imagen para otros fines.
                    </p>
                    <p>
                        En línea con lo expuesto se aclara que Liderarte Performance y Coaching SA de CV y/o Tus Cinco Poderes no esta obligado a utilizar tu imagen en forma fija o en movimiento, incluyendo tu voz o testimonio escrito, ni abonara contraprestación alguna con relación al uso de tu imagen en forma fija o en movimiento, incluyendo tu voz o testimonio escrito en el sentido y con los efectos previamente detallados.
                    </p>
                </div>
                <div className="paragraph">
                    <div className="title">
                        LEGITIMACIÓN DEL TRATAMIENTO DE DATOS
                    </div>
                    <p>
                        El uso de tus datos se realiza porque nos das tu consentimiento para usar los que nos proporcionas en los formularios para un uso específico que se indica en cada uno de ellos. Tus datos solo son necesarios para los usos concretos por los que se te solicitan, si no nos los facilitas, esos servicios no son posibles.
                    </p>
                </div>
                <div className="paragraph">
                    <div className="title">
                        TRANSFERENCIAS Y CESIONES DE DATOS
                    </div>
                    <p>
                        Existe un compromiso firme por nuestra parte de que los datos que proporcione a Liderarte Performance & Coaching S.A. de CV y/o Tus Cinco Poderes, no serán vendidos ni cedidos a terceras personas sin el previo consentimiento del interesado bajo ningún concepto o circunstancia, salvo consentimiento expreso u obligación legal.
                    </p>
                    <p>
                        Nuestro sitio web contiene enlaces hacia sitios web de terceros. Tus Cinco Poderes no se hace responsable por las políticas y prácticas de privacidad de estos otros sitios.
                    </p>
                    <p>
                        Nuestro sitio web sincroniza y enlaza con la plataforma Matrix LMS. Tus Cinco Poderes no se hace responsable por las políticas y prácticas de privacidad de Matrix LMS ni de ninguna otra plataforma con la que sincronice en el presente o con la que pudiera sincronizar en el futuro.
                    </p>
                </div>
                <div className="paragraph">
                    <div className="title">
                        DERECHOS DE LOS INTERESADOS
                    </div>
                    <p>
                        Tienes el derecho de acceder a la información que sobre tu persona está almacenada en nuestras bases de datos, rectificarla si existiera alguna errata, suprimirla, limitarla, oponerte a su tratamiento y retirar tu consentimiento si ese es tu deseo. Para ello simplemente debes escribir un e-mail a la dirección de correo electrónico <a href="mailto:contacto@liderarte.com.mx">contacto@liderarte.com.mx</a> donde te atenderemos gustosamente cualquier consulta, comentario o aclaración requerida al respecto.
                    </p>
                </div>
            </div>
        </PrivacyContainer>
    )
}

export default Privacy
