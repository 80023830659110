import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { googleLoginAction, singupEmailAndPassAction } from '../redux/UserDucks'
import { withRouter, Link } from 'react-router-dom'

import { FcGoogle } from "react-icons/fc";
import { useTranslation } from "react-i18next";
import { analytics } from '../firebase';
import { FormContainer, GoogleButton, Form } from './ui/Form';

const Singup = (props) => {

    const dispatch = useDispatch()
    const { t } = useTranslation();

    const loading = useSelector(store => store.user.loading)
    const active = useSelector(store => store.user.active)
    const errorDis = useSelector(store => store.user.error)


    const [ name, setName ] = useState('')
    const [ email, setEmail ] = useState('')
    const [ pass, setPass ] = useState('')
    const [ pass2, setPass2 ] = useState('')
    const [ error, setError ] = useState(null)
    
    useEffect(() => {
        document.querySelector('.navbar').style.display = "flex"
        document.querySelector('.LanguageSelector').style.display = "flex"
        document.querySelector('.navbar').classList.remove('active')
        document.querySelector('.LanguageSelector').classList.remove('active')
        
    },[])
    
    useEffect(() => {
        
        if (active) {
            props.history.push('/')
        }
        
    }, [active, props.history])


    const processData = e => {
        e.preventDefault();

        

        if (!email.trim()) {
            setError(t('insert_email'));
            return
        }
        const validation = !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)
        if (validation) {
            setError(t('correct_email'));
            return
        }
        
        if (!pass.trim()) {
            setError(t('mandatory_pass'));
            return
        }
        if (!pass2.trim()) {
            setError(t('confirm_pass'));
            return
        }
        if (pass.length < 6) {
            setError(t('pass_lenght'));
            return
        }

        if (pass !== pass2) {
            setError(t('pass_no_match'))
            return
        }

        if (!name.trim()) {
            setError(t('insert_name'));
            return
        }
        
        setError(null)

        
        dispatch(singupEmailAndPassAction(name, email, pass, "limited", null))
        analytics.logEvent('sign_up');
    }
    return (
        <FormContainer >
            <h3 className="center">
                {t('create_new_user')}
              </h3>
              <p >
                  {t('join_community')} <br/>
                  <button className="btn"
                                onClick={() => props.history.push('/login')}
                                type="button" >
                            {t('already_have_account')}
                            <span > {t('login_here')}</span>
                      </button>
              </p>
              
                      
                      
              
                
             
              <div className="row justify-content-center d-flex align-items-center body-form">
              <div className="col-sm-12 col-md-5 play-col">
                        <p>
                            Si te han enviado o tienes un codigo para jugar una partida, ingresa aquí
                        </p>
                        <Link className="btn btn-light btn-lg w-75 rounded-pill text-uppercase" to="/game" exact>
                            {t('play')}
                        </Link>
                    </div>
                  
                  <div className="col-sm-12 col-md-6 border-start ps-5">
                  <Form onSubmit={processData}>
                            {
                                errorDis !== null ? (
                                    <div className="alert alert-danger"> {errorDis} </div>
                                ) : null
                            }
                          { error && (
                              <div className="alert alert-danger">
                                  {error}
                              </div>
                          )}

                        
                          <input 
                            type="text"
                            className="form-control mt-3 rounded-pill"
                            placeholder={t('name')}
                            onChange={e => setName(e.target.value)}
                            value={name} 
                            /> 
                           
                          <input 
                            type="email"
                            className="form-control mt-3 rounded-pill"
                            placeholder="Email: example@example.com"
                            onChange={e => setEmail(e.target.value) } 
                            value={email} />
                        <input 
                            type="password"
                            className="form-control mt-3 rounded-pill"
                            placeholder={t('password')}
                            onChange={e => setPass(e.target.value) }
                            value={pass} 
                            pattern=".{6,}"
                            />
                        <input 
                            type="password"
                            className="form-control mt-3 rounded-pill"
                            placeholder={t('confirm_pass2')}
                            onChange={e => setPass2(e.target.value) }
                            value={pass2}
                            pattern=".{6,}"
                             />

                        <p className="fw-light mt-3" style={{fontSize: '10px'}} >
                            {t('terms_one')} <Link to="/terms-conditions">
                                {t('footer_terms')}
                            </Link>
                        </p>
                        <button className="btn btn-dark btn-lg rounded-pill mt-2 w-100" type="submit" >
                            {t('join')}
                        </button>
                        
                        
                      </Form>
                      
                      <div className="row  justify-content-center">
                        <p className="title-google" >{t('also_use')}</p>
                        <div className="row d-flex justify-content-center">
                        <GoogleButton className="btn btn-outline-danger btn-lg rounded-pill"
                                onClick={() => dispatch(googleLoginAction())} 
                                disabled={loading}
                                > 
                                <div ><FcGoogle /></div> 
                            oogle
                        </GoogleButton>
                        {/* <button className="btn btn-danger btn-lg w-75 mt-3"
                                style={{background: '#058CFF', borderColor: '#058CFF'}}
                                onClick={() => dispatch(facebookLoginAction())} 
                                disabled={loading}
                                > Facebook
                        </button> */}
                        
                        </div>
                      </div>
                  </div>
                  
              </div>
            
        </FormContainer>
    )
}

export default withRouter(Singup)
