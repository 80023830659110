import React from 'react'
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import { FooterContainer, Copyright, Col } from './ui/Footer'

import logo from '../assets/img/logo-en.png'
import logoES from '../assets/img/logo-1.png'
import { FaFacebookSquare, FaInstagram, FaTwitterSquare, FaLinkedin } from "react-icons/fa";

const Footer = () => {

    const { t, i18n } = useTranslation();
    
    return (
        <FooterContainer>
            
            <div className="container" >
                
                <div className="d-flex justify-content-between">
                    <Col className="col-xs-12 col-lg-4">
                    <Link className="navbar-brand" to="/">
                        <img src={i18n.language === "en" ? logo : logoES} alt="cinco poderes" width="150"/>
                    </Link>
                    </Col>
                    <Col className="col-xs-12 col-lg-4">
                        <p className="title">
                            {t('footer_contact')}
                        </p>
                        <p className="email" >
                            <a target="_blank"href="mailto:hello@yourfivepowers.com">hello@yourfivepowers.com</a>
                        </p>
                        {/* <p className="phone">
                            <a href="tel:+54 9 11 5403-4924">+54 9 11 5403-4924</a>
                        </p> */}
                        <div className="social">
                            <div className="row">
                            
                            </div>
                            <div className="row social-icons">
                            <a href="https://www.instagram.com/yourfivepowers/">
                                <FaInstagram />
                            </a>
                            <a href="https://www.facebook.com/liderarte.organizacional/">
                                <FaFacebookSquare />
                            </a>
                            
                            <a href="https://twitter.com/liderartemx">
                                <FaTwitterSquare />
                            </a>
                            <a href="https://www.linkedin.com/company/liderarte-performance-coaching/">
                                <FaLinkedin />
                            </a>
                            {/* <a href="https://www.youtube.com/channel/UC3cBUjSRsQmqTZ4duBNT14g/videos">
                                <FaYoutube />
                            </a> */}
                            </div>
                        </div>
                    </Col>
                    
                    <Col className="col-xs-12 col-lg-4">
                        <p className="title">
                            {t('footer_links')}
                        </p>
                        <Link to="https://liderarte.com.mx/">
                            Liderarte
                        </Link>
                        <Link to="/privacy">
                            {t('footer_privacy')}
                        </Link>
                        <Link to="/terms-conditions">
                            {t('footer_terms')}
                        </Link>
                    </Col>
                </div>
            <Copyright>
                <p>
                    {t('copyright')}
                </p>
            </Copyright>
            </div>
        </FooterContainer>
    )
}

export default Footer
