import styled from '@emotion/styled';
import backdash from '../../assets/img/back-dash.jpeg'

export const PrivacyContainer = styled.div`
    display: flex;
    padding: 50px 0;
    flex-direction: row;
    flex-wrap: wrap;
    background: url(${backdash});
    background-size: contain;
    background-repeat: repeat;
    color: #fff;

    h3{
        margin-bottom: 40px;
    }
    .paragraph{
        margin-bottom: 40px;

        .title{
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 20px;
        }

        a{
            text-decoration: none;
            color: #fece1e;
        }
    }
`