export default [
    {
        id: "pal-1",
class: "pal",
        question:'¿Qué juicios tengo acerca de mi mismo en esta situación?',
    title: "palabras", 
},
    {
        id: "pal-2",
class: "pal",
        question:'¿Cuándo dejé de escuchar?',
    title: "palabras", 
},
    {
        id: "pal-3",
class: "pal",
        question:'¿A qué te gustaría decir que no en esta situación?',
    title: "palabras", 
},
    {
        id: "pal-4",
class: "pal",
        question:'¿Cuántas veces dijiste que si cuando querías decir que no en relación a este tema? ¿Qué podrías hacer ahora?',
    title: "palabras", 
},
    {
        id: "pal-5",
class: "pal",
        question:'¿Qué historia te cuentas acerca de este tema/situación? ¿Qué historia te gustaría contarte?',
    title: "palabras", 
},
    {
        id: "pal-6",
class: "pal",
        question:'Define esta situación con cinco palabras.',
    title: "palabras", 
},
    {
        id: "pal-7",
class: "pal",
        question:'Defínete a ti mismo en esta situación con cinco palabras.',
    title: "palabras", 
},
    {
        id: "pal-8",
class: "pal",
        question:'¿Cuál es la palabra que más poder te daría ahora?',
    title: "palabras", 
},
    {
        id: "pal-9",
class: "pal",
        question:'¿Cuáles son las reglas necesarias que definen los límites de lo posible para ti en este tema/situación?',
    title: "palabras", 
},
    {
        id: "pal-10",
class: "pal",
        question:'¿A quién/es deberías agradecer? ¿Lo has hecho?',
    title: "palabras", 
},
    {
        id: "pal-11",
class: "pal",
        question:'Describe con hechos y afirmaciones la situación actual',
    title: "palabras", 
},
    {
        id: "pal-12",
class: "pal",
        question:'¿Cómo se relacionan tus expectativas con lo que realmente está pasando?',
    title: "palabras", 
},
    {
        id: "pal-13",
class: "pal",
        question:'¿Con quién/quienes necesitas conversar y no lo estás haciendo?',
    title: "palabras", 
},
    {
        id: "pal-14",
class: "pal",
        question:'¿Califica del 1 al 10 el nivel de conversaciones que estás teniendo para abordar este tema? ¿Por qué? ¿Qué te está faltando?',
    title: "palabras", 
},
    {
        id: "pal-15",
class: "pal",
        question:'Las preguntas, determinan las respuestas en tu vida. ¿Qué preguntas te estás haciendo en relación con este tema? ¿Qué preguntas no te estás haciendo que podrían hacer una diferencia?',
    title: "palabras", 
},
    {
        id: "pal-16",
class: "pal",
        question:'¿Cuál es el nombre del juego que estás jugando? ¿Qué otros juegos secundarios se desprenden de este juego?',
    title: "palabras", 
},
    {
        id: "pal-17",
class: "pal",
        question:'¿Cómo habla de ti este tema/situación?',
    title: "palabras", 
},
    {
        id: "pal-18",
class: "pal",
        question:'Menciona por lo menos tres beneficios ocultos que te da estar donde estás.',
    title: "palabras", 
},
    {
        id: "pal-19",
class: "pal",
        question:'¿Cuáles son los valores involucrados en esta situación/tema? ¿Estás siendo coherente con ellos?',
    title: "palabras", 
},
    {
        id: "pal-20",
class: "pal",
        question:'¿Qué necesitas decirte a ti mismo acerca de este tema/situación?',
    title: "palabras", 
},
]