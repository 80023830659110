import axios from "axios";


export async function getClientToken() {
    const response = await axios('/api/paypal-token'); 
    
    return await response.data;
}


