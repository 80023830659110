import React, { useEffect } from 'react'
//import axios from 'axios';

import { useLocation } from 'react-router-dom'; //useHistory
//import { useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";

//import { updateUserSubscription } from '../../redux/UserDucks';
import { analytics } from '../../firebase';

const CancelButton = ({user}) => {

    
    // const dispatch = useDispatch()
    // let history = useHistory();
    const location = useLocation();

    const { t } = useTranslation();

    
    // const cancelSubscription = async () => {
    //     const { payPalId, paypalToken } = user
        
        
    //     const _url = `https://api-m.paypal.com/v1/billing/subscriptions/${payPalId}/cancel`
        
    //     if (paypalToken !== undefined) {
    //         await axios({
    //         url: _url,
    //         method: 'POST',
    //         headers: { "Access-Control-Allow-Origin": "*", "Content-Type": "application/json", "Authorization": paypalToken },
    //         data: { "reason": "Not satisfied with the service" }
    //         }).then(res => {
    //             console.log(res);
    //             dispatch(updateUserSubscription(user.email, "limited", null ))
    //             analytics.logEvent('refund');
    //             history.push('dashboard')
    //         })

            
            
    //     } else {
    //         alert(t('reload_page'))
            
    //     }
        
        
    //     // await axios({
    //     //     url: _url,
    //     //     method: 'post',
    //     //     headers: { "Access-Control-Allow-Origin": "*", "Content-Type": "application/json", "Authorization": paypalToken },
    //     //     data: { "reason": "Not satisfied with the service" }
    //     // }).then(res => {
    //     //         dispatch(updateUserSubscription(user.email, "limited", null ))
    //     //         analytics.logEvent('refund');
    //     //         history.push('dashboard')
    //     //     });
        
    // }
    
    useEffect(() => {
         if( user !== undefined ){
            if (user.subscriptionType === 'limited') {
                document.getElementById('cancel_button').style.display = 'none'
            }
        }
        analytics.logEvent('screen_view', { firebase_screen: location.pathname});
    }, [user, location.pathname])

    return (
        <div id="cancel_button" className="mt-5">
            
            {/* <button onClick={() => cancelSubscription()}  type="button" className="btn btn-danger rounded-pill text-uppercase">
                {t('cancel_plan')}
            </button> */}
            <p> {t('cancel_policy')} <br/>
            <a style={{color: '#fece1e'}} href="mailto:hello@yourfivepowers.com">hello@yourfivepowers.com</a>
            </p>
            
            <p>* {t('cancelations_text')} </p>
        </div>
    )
}

export default CancelButton
