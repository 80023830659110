import styled from '@emotion/styled';

export const FooterContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    background: #212121;
    color: #aeaeae;
    width: 100%;
    padding-top: 30px;

    @media screen and (max-width: 600px) {
        flex-wrap: wrap;
        flex-direction: row;

        .container{
            padding-left: 20px
        }
        .d-flex{
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: center!important;
        }
    }
    
`

export const Col = styled.div`
    display: flex;
    flex-direction: column;

    .title{
        font-weight: 500;
        font-size: 18px;
        color: #d6d6d4;
        letter-spacing: 1.5px;
        margin-bottom: 10px;
    }

    a{
        color: inherit;
        text-decoration: none;
        margin-bottom: 5px;

        &:hover{
            font-weight: 500;
            color: #fff;
        }
    }
    
    .email{
        width: fit-content;
        border-bottom: 1px solid #aeaeae;
    }

    .phone{
        font-weight: 500;
    }
    
    .social{
        margin-top: 10px;
        
        &-icons{
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            
            a{
                width: 25px;
                font-size: 25px;
                margin-right: 15px;

                &:hover{
                    font-weight: 500;
                    color: #fff;
                }
            }
        }
    }

    @media screen and (max-width: 600px){
        align-items: center;
        justify-content: center;
        .title{
            margin-top: 20px;
        }
    }
`

export const Copyright = styled.div`
    margin-top: 30px;
    border-top: 1px solid #2a2a2f;
    font-size: 16px;
    padding: 20px 0;
    text-align: center;
`
