export default [
    {   
        id : "acc-1",
class: "acc",
        question:'¿Qué te impide moverte hacia delante?',
        title: "accion", 
},
    {
        id: "acc-2",
class: "acc",
        question:'¿Qué es lo que impulsa tu acción en este tema?',
        title: "accion", 
},
    {
        id: "acc-3",
class: "acc",
        question:'¿Pediste ayuda?¿A quién?',
    title: "accion", 
},
    {
        id: "acc-4",
class: "acc",
        question:'¿Qué necesitas hacer para tener éxito?',
    title: "accion", 
},
    {
        id: "acc-5",
class: "acc",
        question:'¿Qué te paraliza?',
    title: "accion", 
},
    {
        id: "acc-6",
class: "acc",
        question:'¿Si el compromiso es acción, cuál es tu compromiso?',
    title: "accion", 
},
    {
        id: "acc-7",
class: "acc",
        question:'¿En qué estado necesitas estar para ponerte en acción?',
    title: "accion", 
},
    {
        id: "acc-8",
class: "acc",
        question:'Enumera tres excusas por las cuales no estás tomando las decisiones necesarias para accionar en este tema',
    title: "accion", 
},
    {
        id: "acc-9",
class: "acc",
        question:'Enumera tres motivos por los cuales deberías tomar acciones en este tema',
    title: "accion", 
},
    {
        id: "acc-10",
class: "acc",
        question:'¿Cuál sería un resultado visible para ti y para otros en función de las acciones que tomes en este tema?',
    title: "accion", 
},
    {
        id: "acc-11",
class: "acc",
        question:'¿Qué recursos necesitas para tomar acción? ¿Has hecho algo al respecto?',
    title: "accion", 
},
    {
        id: "acc-12",
class: "acc",
        question:'¿Cuál es el resultado mínimo, aceptable no negociable del cuál partes?',
    title: "accion", 
},
    {
        id: "acc-13",
class: "acc",
        question:'¿Qué datos duros, reales y cuantitativos tienes acerca de la situación actual de este tema?',
    title: "accion", 
},
    {
        id: "acc-14",
class: "acc",
        question:'¿Qué corporalidad necesitas encarnar para abordar este tema/situación?',
    title: "accion", 
},
    {
        id: "acc-15",
class: "acc",
        question:'¿Cuál es tu propósito?',
    title: "accion", 
},
    {
        id: "acc-16",
class: "acc",
        question:'¿Si pudieras dar algo por anticipado, qué sería?',
    title: "accion", 
},
    {
        id: "acc-17",
class: "acc",
        question:'¿Si pudieras hacer un intercambio, cuál sería?',
    title: "accion", 
},
    {
        id: "acc-18",
class: "acc",
        question:'¿Qué has hecho tú, para que las cosas ocurrieran como ocurrieron?',
    title: "accion", 
},
    {
        id: "acc-19",
class: "acc",
        question:'¿Qué dejaste de hacer por lo cual estás donde estás?',
    title: "accion", 
},
    {
        id: "acc-20",
class: "acc",
        question:'¿De 1 al 10 qué tan importante es tomar acción en este tema? ¿Si aún no lo has hecho, qué estás esperando?',
    title: "accion", 
},
    {
        id: "acc-21",
class: "acc",
        question:'¿Tienes un plan de acción? ¿Cuál es el primer paso?',
    title: "accion", 
},
]