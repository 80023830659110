export default [
    {
        id: "sor-1",
class: "sorprise",
        question:'What is the worst thing that can happen if everything went wrong?',
    title: "surprise", 
},
    {
        id: "sor-2",
class: "sorprise",
        question:'If this situation/subject was a song, which will it be?',
    title: "surprise", 
},
    {
        id: "sor-3",
class: "sorprise",
        question:'What is it that you haven’t thought about yet?',
    title: "surprise", 
},
    {
        id: "sor-4",
class: "sorprise",
        question:'Take a decision card and do what it tells you',
    title: "surprise", 
},
    {
        id: "sor-5",
class: "sorprise",
        question:'If you could ask three wishes, what would they be?',
    title: "surprise", 
},
    {
        id: "sor-6",
class: "sorprise",
        question:'If this situation/subjetc was a movie, what would it be?',
    title: "surprise", 
},
    {
        id: "sor-7",
class: "sorprise",
        question:'If a child had the answer, what would it be?',
    title: "surprise", 
},
    {
        id: "sor-8",
class: "sorprise",
        question:'What would be a unexpected result?',
    title: "surprise", 
},
    {
        id: "sor-9",
class: "sorprise",
        question:'Find three possible alternatives that you haven´t considered before',
    title: "surprise", 
},
    {
        id: "sor-10",
class: "sorprise",
        question:'If your life was at stake, what would you do in this subject/situation?',
    title: "surprise", 
},
    {
        id: "sor-11",
class: "sorprise",
        question:'Do you want this or do you want to be right?',
    title: "surprise", 
},
    {
        id: "sor-12",
class: "sorprise",
        question:'Find two stoppers. Transform them into opportunities.',
    title: "surprise", 
},
    {
        id: "sor-13",
class: "sorprise",
        question:"If destiny gave you a surprise, what would it be?",
    title: "surprise", 
},
    {
        id: "sor-14",
class: "sorprise",
        question:'Defend the opposite point of view from yours for this subject/situation',
    title: "surprise", 
},
    {
        id: "sor-15",
class: "sorprise",
        question:'What would be the legacy for someone you love if you get what you want?',
    title: "surprise", 
},
    {
        id: "sor-16",
class: "sorprise",
        question:'Draw the result in a piece of paper',
    title: "surprise", 
},
    {
        id: "sor-17",
class: "sorprise",
        question:'Ask another player in the game what he/she would do in this situation/subject',
    title: "surprise", 
},
    {
        id: "sor-18",
class: "sorprise",
        question:'Listen to other´s points of view for this subject/situation',
    title: "surprise", 
},
    {
        id: "sor-19",
class: "sorprise",
        question:'What can you learn from this subject/situation?',
    title: "surprise", 
},
    {
        id: "sor-20",
class: "sorprise",
        question:'Turn roles around: if you were the other person, how would you see yourself?',
    title: "surprise", 
},
//     {
//         id: "sor-21",
// class: "sorprise",
//         question:'Ask the player on the right what he would do in this situation?',
//     title: "surprise", 
// },
//     {
//         id: "sor-22",
// class: "sorprise",
//         question:'If a child had the answer, what would he tell you?',
//     title: "surprise", 
// },
//     {
//         id: "sor-23",
// class: "sorprise",
//         question:"Get a child's answer",
//     title: "surprise", 
// },
//     {
//         id: "sor-24",
// class: "sorprise",
//         question:"What question haven't you asked yourself yet?",
//     title: "surprise", 
// },
]