export default [
    {
        id: "pen-1",
class: "pensa",
        question:'¿Qué piensas acerca de este tema/situación?',
    title: "pensamientos", 
},
    {
        id: "pen-2",
class: "pensa",
        question:'¿Qué necesitas pensar acerca de este tema que te de poder y protagonismo?',
    title: "pensamientos", 
},
    {
        id: "pen-3",
class: "pensa",
        question:'¿Qué significados/creencias necesitas para cambiar tu punto de vista frente a esta situación?',
    title: "pensamientos", 
},
    {
        id: "pen-4",
class: "pensa",
        question:'¿Lo que piensas acerca de este tema, te está ayudando? ¿Quisieras cambiarlo?',
    title: "pensamientos", 
},
    {
        id: "pen-5",
class: "pensa",
        question:'¿Cuánto de lo que piensas acerca de este tema está determinado por tu pasado?',
    title: "pensamientos", 
},
    {
        id: "pen-6",
class: "pensa",
        question:'¿En qué lugar estás parado frente a este tema: pasado, presente o futuro? ¿Cuáles son las consecuencias?',
    title: "pensamientos", 
},
    {
        id: "pen-7",
class: "pensa",
        question:'¿Qué estás callando? ¿Qué no te atreves a decir? ¿A quienes?',
    title: "pensamientos", 
},
    {
        id: "pen-8",
class: "pensa",
        question:'¿Si pudieras mirar hacia atrás, qué lecciones aprendiste que podrías relacionar con tu situación actual?',
    title: "pensamientos", 
},
    {
        id: "pen-9",
class: "pensa",
        question:'¿Si miras al futuro, y resolviste este tema, qué fue lo que hiciste para lograrlo?',
    title: "pensamientos", 
},
    {
        id: "pen-10",
class: "pensa",
        question:'¿Qué te falta para estar presente?',
    title: "pensamientos", 
},
    {
        id: "pen-11",
class: "pensa",
        question:'¿Qué rol está jugando el ego en este tema/situación?',
    title: "pensamientos", 
},
    {
        id: "pen-12",
class: "pensa",
        question:'¿Cuáles de tus viejas creencias están en contradicción con este tema/situación? ¿Cuánto poder les estás dando?',
    title: "pensamientos", 
},
    {
        id: "pen-13",
class: "pensa",
        question:'¿Te sirve lo que estás haciendo? ¿Si no es así, qué te serviría?',
    title: "pensamientos", 
},
    {
        id: "pen-14",
class: "pensa",
        question:'¿Cuáles son las conductas que van en contra de este tema/situación?',
    title: "pensamientos", 
},
    {
        id: "pen-15",
class: "pensa",
        question:'¿Has pensado en qué variables vas a medir para saber que estás avanzando?',
    title: "pensamientos", 
},
    {
        id: "pen-16",
class: "pensa",
        question:'¿Qué pensamientos te tienen? Qué podrías hacer al respecto?',
    title: "pensamientos", 
},
    {
        id: "pen-17",
class: "pensa",
        question:'¿Qué te está diciendo tu “vocecita interior” en este momento?',
    title: "pensamientos", 
},
    {
        id: "pen-18",
class: "pensa",
        question:'¿Qué significa para ti este proyecto? ¿Cuánto estás poniendo en juego? ¿Lo has compartido?',
    title: "pensamientos", 
},
    {
        id: "pen-19",
class: "pensa",
        question:'¿Cuál es tu visión?',
    title: "pensamientos", 
},
    {
        id: "pen-20",
class: "pensa",
        question:'¿Qué tienes que olvidar?¿Qué tienes que perdonar?',
    title: "pensamientos", 
},
]