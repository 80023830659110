import React from 'react'
import { useTranslation } from "react-i18next";
import { PrivacyContainer } from './ui/Privacy'


const Terms = () => {

    const { t } = useTranslation();

    return (
        <PrivacyContainer>
           <div className="container">
               <h3>
                   {t('footer_terms')}
               </h3>
            <div className="paragraph">
                <p>
                    Esta página web es propiedad y está operado por Liderarte Performance & Coaching SA de CV y/o Tus Cinco Poderes operada por Marcela Fernández y Laura Bicondoa. Estos Términos establecen los términos y condiciones bajo los cuales tu puedes usar nuestra página web y servicios ofrecidos por nosotros. Esta página web ofrece a los visitantes contenido, entrenamientos y servicios para el desarrollo personal y profesional (los Servicios). Al acceder o usar la página web de nuestro servicio, usted aprueba que haya leído, entendido y aceptado estar sujeto a estos Términos:
                </p>
                <p>
                    Para usar nuestra página web y / o recibir nuestros Servicios, debes tener al menos 18 años de edad, o la mayoría de edad legal en tu jurisdicción, y poseer la autoridad legal, el derecho y la libertad para participar en estos Términos como un acuerdo vinculante. No tienes permitido utilizar esta página web y / o recibir Servicios si hacerlo está prohibido en tu país o en virtud de cualquier ley o regulación aplicable a tu caso.
                </p>
                <p>
                    Al comprar cualquiera de nuestros Servicios, aceptas que: (i) eres responsable de leer el listado completo del artículo antes de comprometerte a comprarlo: (ii) celebras un contrato legalmente vinculante para comprar un artículo cuando te comprometes a comprar cualquiera de los Servicios y completar el proceso de facturación y pago.
                </p>
                <p>
                    Los precios que cobramos por usar nuestros Servicios se enumeran en la página web y/o los que oportunamente se informen por cualquier otra vía como mail, WhatsApp o de manera telefónica por cualquiera de nuestros representantes. Nos reservamos el derecho de cambiar nuestros precios para los Servicios que se muestran en cualquier momento y de corregir los errores de precios que pueden ocurrir inadvertidamente. Cualquier información adicional sobre precios e impuestos sobre las ventas estará disponible en la página de pagos y/o se te informará por cualquiera de las vías previamente detalladas. 
                </p>
                <p>
                    La tarifa por los Servicios y cualquier otro cargo, en relación con el uso de los Servicios, como los impuestos y las posibles tarifas de transacción, de existir los mismos, se cobrarán juntamente con el pago de los Servicios en la misma forma y condiciones elegidas.
                </p>
                <p>
                    Podemos, sin aviso previo, cambiar los Servicios; dejar de proporcionar los Servicios o modificar cualquier característica de los mismos; o crear límites para los Servicios. Podemos suspender de manera permanente o temporal el acceso a los Servicios sin previo aviso ni responsabilidad por cualquier motivo, o sin ningún motivo.
                </p>
                <p>
                    Los Servicio y todos los materiales incluidos o transferidos, incluyendo, sin limitación, software, imágenes, texto, gráficos, logotipos, patentes, marcas registradas, marcas de servicio, derechos de autor, fotografías, audio, videos, música y todos los Derechos de Propiedad Intelectual relacionados con ellos, son la propiedad exclusiva de Liderarte Performance & Coaching SA de CV y/o Tus Cinco Poderes y/o de quien se indique en el material. Salvo que se indique explícitamente en este documento, no se considerará que nada en estos Términos crea una licencia en o bajo ninguno de dichos Derechos de Propiedad Intelectual, y tu aceptas no vender, licenciar, alquilar, modificar, distribuir, copiar, reproducir, transmitir, exhibir públicamente, realizar públicamente, publicar, adaptar, editar o crear trabajos derivados de los mismos.
                </p>
                <p>
                    Podemos terminar o suspender de manera permanente o temporal tu acceso a los Servicios sin previo aviso y responsabilidad por cualquier razón, incluso si a nuestra sola determinación violas alguna disposición de estos Términos o cualquier ley o regulación aplicable.
                </p>
            </div>
            <div className="paragraph">
                <p>
                    En el caso que hubieras adquirido alguno de nuestros Servicios es importante que tengas en cuenta:
                </p>
                <p>
                    <strong>SUBSCRIPCIÓN:</strong> una vez adquirida la subscripción, a su vencimiento se te informara con al menos 48hs de anticipación el vencimiento de la misma, pasado ese término y sin que hubieras informado tu voluntad de no renovación, la misma quedara automáticamente renovada por igual periodo.
                </p>
                <p>
                    En cualquier caso, podrás cancelar tu cuenta y subscripción en cualquier momento. Sin perjuicio de ello, con respecto a la suscripción de subscripcións renovadas automáticamente, dichas suscripciones se suspenderán solo al vencimiento del período correspondiente por el que ya has realizado el pago.
                </p>
                <p>
                    Usted acuerda indemnizar y eximir de responsabilidad a Liderarte Performance & Coaching SA de CV y/o Tus Cinco Poderes de cualquier demanda, pérdida, responsabilidad, reclamación o gasto (incluidos los honorarios de abogados) que terceros realicen en tu contra como consecuencia de, o derivado de, o en relación con tu uso de la página web o cualquiera de los Servicios ofrecidos en la página web
                </p>
                <p>
                    En la máxima medida permitida por la ley aplicable, en ningún caso el Liderarte Performance & Coaching SA de CV y/o Tus Cinco Poderes será responsable por daños indirectos, punitivos, incidentales, especiales, consecuentes o ejemplares, incluidos, entre otros, daños por pérdida de beneficios, buena voluntad, uso, datos. u otras pérdidas intangibles, que surjan de o estén relacionadas con el uso o la imposibilidad de utilizar los Servicios. 
                </p>
                <p>
                    En la máxima medida permitida por la ley aplicable Liderarte Performance & Coaching SA de CV y/o Tus Cinco Poderes no asume responsabilidad alguna por (i) errores, errores o inexactitudes de contenido; (ii) lesiones personales o daños a la propiedad, de cualquier naturaleza que sean, como resultado de tu acceso o uso de nuestros Servicios; y (iii) cualquier acceso no autorizado o uso de nuestros servidores seguros y / o toda la información personal almacenada en los mismos.
                </p>
                <p>
                    Nos reservamos el derecho de modificar estos términos de vez en cuando a nuestra entera discreción. Por lo tanto, debes revisar estas páginas periódicamente. Cuando cambiemos los Términos de una manera material, te notificaremos que se han realizado cambios importantes en los Términos. El uso continuado de la página web o nuestros Servicio después de dicho cambio constituye tu aceptación de los nuevos Términos. Si no aceptas alguno de estos términos o cualquier versión futura de los Términos, no uses o accedas (o continúes accediendo) a la página web o al servicio.
                </p>
                <p>
                    Acepta recibir de vez en cuando nuestros mensajes y materiales de promoción, por correo postal, correo electrónico o cualquier otro formulario de contacto que nos proporciones (incluido tu número de teléfono para llamadas o mensajes de texto). Si no deseas recibir dichos materiales o avisos de promociones, simplemente avísanos en cualquier momento.
                </p>
                <p>
                    Estos Términos, los derechos y recursos provistos aquí, y todos y cada uno de los reclamos y disputas relacionados con este y / o los Servicios, se regirán, interpretarán y aplicarán en todos los aspectos única y exclusivamente de conformidad con las leyes sustantivas internas de México, sin respeto a sus principios de conflicto de leyes. Todos los reclamos y disputas se presentarán y usted acepta que sean decididos exclusivamente por un tribunal de jurisdicción competente ubicada en Ciudad de México.
                </p>
            </div>
           </div>
        </PrivacyContainer>
    )
}

export default Terms
