import styled from '@emotion/styled';

import backdash from '../../assets/img/back-dash.jpeg'

export const FormContainer = styled.div`
    background: url(${backdash});
    text-align: center;
    padding-top: 50px;
    padding-bottom: 80px;
    color: #fff;
    min-height: calc(100vh - 360px);
    background-size: cover;

    .body-form{
        margin-top: 40px;
    }

    button{
        color: inherit;
        span{
            color: #fece1e;
        }
    }

    a{
        color: #fece1e;
    }

    .btn:hover{
        color: #fff;
    }

    .play-col{
        p{
            width: 70%;
            margin: auto;
            margin-bottom: 30px;
        }
        a{
            width: 150px;
            font-weight: 500;
            color: #fff;
            background-color: transparent;
            border-color: #f15951;

            &:hover{
                background-color: rgba(241, 89, 81, 1);
            }
        }
    }

    @media screen and (max-width: 600px){
        padding-top: 30px;
        
        .border-start{
            padding: 0px!important;
            width: 100%;
        }

        .body-form{
            width: 90%;
            margin: auto;
            margin-top: 30px;

            .border-start{
                border-left: 0!important;
            }

            .play-col{
                padding-bottom: 20px;
                border-bottom: 1px solid #fff;

                p{
                    width: 100%;
                    margin-bottom:10px;
                }

                a{
                    background-color: rgba(241, 89, 81, 1)
                }
            }
        }

        .title-google{
            margin-top: 20px;
        }
    }
`

export const GoogleButton = styled.button`
    display: flex;
    justify-content: center;
    color: #FF6665!important;
    border-color: #FF6665;
    margin: auto;
    width: 75%;

    &:hover{
        color: #ffffff!important;
        background-color: rgba(255, 102, 101, 0.5);
    }

    div{
        margin-top: -2px;
        width: 20px;
        color: red;
    }

    @media screen and (max-width: 600px){
        width: 100%;
    }
`

export const Form = styled.form`
    input{
        background-color: rgba(194, 193, 193, 0.13);
        color: #fff;
        border: none;

        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #fff;
            opacity: 1; /* Firefox */
        }
        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: #fff;
        }

        &::-ms-input-placeholder { /* Microsoft Edge */
            color: #fff;
        }
    }
    
    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus, 
    input:-webkit-autofill:active  {
        background-color: rgba(194, 193, 193, 0.13)!important;
        color: #fff!important;
        border: none!important;
    }

    @media screen and (max-width: 600px){
        
        margin: auto;
    }
    
`