export default [
    {
        id: "dec-1",
class: "decision",
        question: "¿Cuáles son las evidencias de este tema/situación?. Enuméralas.",
    title: "decision", 
},
    {
        id: "dec-2",
class: "decision",
        question: "SUSTENTO: ¿Cómo vas a sostener el resultado?",
    title: "decision", 
},
    {
        id: "dec-3",
class: "decision",
        question: "Fundamento: Fundamenta tu juicio o punto de vista/pasos y luego fundamenta el juicio o punto de vista contrario.",
    title: "decision", 
},
    {
        id: "dec-4",
class: "decision",
        question: "Tiempo: ¿Cuándo vas a tomar acción?",
    title: "decision", 
},
    {
        id: "dec-5",
class: "decision",
        question: "Medición: ¿Qué te indicará que estás avanzando en lo que te propones?¿Cómo y cada cuándo vas a medir? ¿Tu medida es visible para ti y para otros?",
    title: "decision", 
},
    {
        id: "dec-6",
class: "decision",
        question: "Red de ayuda: ¿Necesitas ayuda? ¿La pediste? ¿A quiénes? ¿Los que te ayudan pueden hacerlo? ¿Confías en ellos? Si-No ¿Por qué?",
    title: "decision", 
},
    {
        id: "dec-7",
class: "decision",
        question: "Consecuencias: ¿Qué pasa si lo logras? ¿Qué pasa si no lo logras? ¿Quién es responsable por este resultado?",
    title: "decision", 
},
    {
        id: "dec-8",
class: "decision",
        question: "¿A quienes afecta tu decisión?",
    title: "decision", 
},
    {
        id: "dec-9",
class: "decision",
        question: "¿Involucrados: Identifica personas claves: interlocutor valido; con poder de acción; confiable / interlocutor negativo; no confiable; limitante?",
    title: "decision", 
},
    {
        id: "dec-10",
class: "decision",
        question: "Contratiempos: ¿Cuáles son? ¿Cuántos son? ",
    title: "decision", 
},
    {
        id: "dec-11",
class: "decision",
        question: "¿Qué vas a hacer mañana, la semana que viene, en un mes?",
    title: "decision", 
},
    {
        id: "dec-12",
class: "decision",
        question: "¿Cuánto más te tiene que doler para que tomes una decisión?",
    title: "decision", 
},
    {
        id: "dec-13",
class: "decision",
        question: "¿Qué hace que valga la pena correr el riesgo?",
    title: "decision", 
},
    {
        id: "dec-14",
class: "decision",
        question: "¿Cuánto tiempo más estás dispuesto a seguir tolerando?",
    title: "decision", 
},
    {
        id: "dec-15",
class: "decision",
        question: "¿Si sólo te quedara un mes de vida, que harías?",
    title: "decision", 
},
    {
        id: "dec-16",
class: "decision",
        question: "Imagina que ocurre un milagro y te despiertas con todo resuelto, describe qué fue lo que pasó?",
    title: "decision", 
},
    {
        id: "dec-17",
class: "decision",
        question: "¿Qué oportunidad es este tema/situación para ti?",
    title: "decision", 
},
    {
        id: "dec-18",
class: "decision",
        question: "¿Qué haría un loco frente a esta situación?",
    title: "decision", 
},
    {
        id: "dec-19",
class: "decision",
        question: "¿Qué precio pagas por evitar hacer una elección?",
    title: "decision", 
},
    {
        id: "dec-20",
class: "decision",
        question: "¿Debes hacer lo que piensas o lo que sientes?",
    title: "decision", 
},
    
]